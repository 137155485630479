import jquery from "jquery"
import moment from "moment"
import lodash from "lodash"
import "moment/dist/locale/de"

globalThis.jQuery = globalThis.$ = jquery
globalThis._ = lodash

moment.locale("de")
globalThis.moment = moment

let App = {}
globalThis.App = App

let Turbolinks = {}
globalThis.Turbolinks = Turbolinks

$(document)
  .on("dragstart", function (e) {
    window.draggingDocument = true
  })
  .on("dragend", function (e) {
    window.draggingDocument = false
  })

$(document).on("click", "[data-submit]", function (e) {
  e.preventDefault()
  e.stopPropagation()

  $(this).addClass("loading")
  const id = $(this).data("submit")
  setTimeout(() => $(`#${id}`).trigger("submit"), 50)
})

$(document).on("ajaxComplete", () => $("[data-submit]").removeClass("loading"))
