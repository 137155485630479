export default {
  namespaced: true,

  state: {
    items: [],
  },

  getters: {
    worklists: state => {
      return state.items.filter(o => o.workList && o.forEntity == "for_contacts")
    },
    propertyWorklists: state => {
      return state.items.filter(o => o.workList && o.forEntity == "for_properties")
    },
    mailchimpItems: state => {
      return state.items.filter(o => o.forEntity == "for_contacts" && o.mcExportable)
    },
  },

  mutations: {
    setItems(state, items) {
      state.items = items
    },
    replaceItem(state, item) {
      state.items = state.items.filter(o => o.id != item.id).concat([item])
    },
    updateItem(state, item) {
      state.items = state.items.map(obj => item.find(o => o.id === obj.id) || obj)
    },
    removeItem(state, itemId) {
      state.items = state.items.filter(o => o.id !== itemId)
    },
  },
}
