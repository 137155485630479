// const STORAGE_KEY = "last-visited"
export default {
  namespaced: true,

  state: {
    items: []
  },

  mutations: {
    setItems(state, items) {
      state.items = items
    },
    addItem(state, item) {
      state.items = [item, ...state.items.filter(o => o.id != item.id || o.type != item.type)].slice(0, 5)
    }
  }
}
