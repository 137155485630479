import { datadogRum } from "@datadog/browser-rum"
import config from "./config"

if (config.ENV_TYPE !== "dev") {
  datadogRum.init({
    applicationId: "783c88cb-932f-4554-87f4-cbbfeca65561",
    clientToken: "pubcf93d5fa91507c4f7ec0f211a82edef9",
    site: "datadoghq.com",
    service: "propstack-crm",
    env: config.ENV_TYPE,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    // sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    // trackLongTasks: true,
    defaultPrivacyLevel: "mask",
  })
}
