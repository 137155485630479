import moment from "moment"
import axios from "../config/axios"
import db, { ActiveRecord } from "../config/db"
import cache from "@/config/memory-cache"
import apiService from "../plugins/api"
import { Router } from "vue-router"
import { reactive } from "vue"

const gqlQuote = val => `"${val}"`

const gqlArray = val => `[${val.join(", ")}]`

export type GraphQLClient = <T = any>(
  query: string,
  params?: any,
  shouldCacheResult?: boolean,
  forceRefresh?: boolean,
  throwError?: boolean
) => Promise<T | undefined>

function gqlArrayToUnquotedJSON(obj) {
  const objString = Object.entries(obj)
    .map(([key, value]) => `${key}:${JSON.stringify(value)}`)
    .join(",")

  return `{${objString}}`
}

const prepareValue = val => {
  if (val instanceof Array) {
    return gqlArray(val.map(v => prepareValue(v)))
  }
  if (moment.isMoment(val) || val instanceof Date) {
    return gqlQuote(moment(val).utc().format())
  }
  if (typeof val === "number" || val instanceof Number || typeof val === "boolean" || val instanceof Boolean) {
    return val.toString()
  }
  if (typeof val === "string" || val instanceof String) {
    if (val.startsWith("$")) {
      return val.substring(1)
    }
    return gqlQuote(val)
  }
  if (val === undefined) {
    return "null"
  }
  if (typeof val === "object" && val !== null) {
    return gqlArrayToUnquotedJSON(val)
  }
  throw new Error(`Undefined GraphQL value type \"${typeof val}\"`)
}

const prepareQuery = (query, params) => {
  if (!params) {
    return query
  }
  return query.replace(/\$(\w+)/g, (_, key) => {
    if (!(key in params)) {
      throw new Error(`Variable "${key}" is not defined in GraphQL params`)
    }
    return prepareValue(params[key])
  })
}

const createGraphQLClient = (router: Router): GraphQLClient => {
  return async function $graphql<T>(
    query,
    params,
    shouldCacheResult = false,
    forceRefresh = false,
    throwError = false
  ): Promise<T> {
    try {
      let cacheKey
      if (shouldCacheResult) {
        const match = /query\s+(\w+)\s*\{/gm.exec(query)
        if (match && match.length > 1) {
          cacheKey = match[1]
          if (!forceRefresh) {
            const result = cache.get(cacheKey)
            if (result) return result
          }
        }
      }
      const response = await axios.post(`/api/v1/graphql`, { query: prepareQuery(query, params) })
      if (!response.data.data) {
        console.error(response.data)
        if (throwError) throw new Error("Fehler beim Laden der Daten aufgetreten.")
      }
      const data = response.data.data
      if (cacheKey) {
        cache.set(cacheKey, data)
      }
      return data
    } catch (e) {
      const errorMessage = e.response?.data?.error?.message || e.response?.data?.errors?.join(", ") || e
      App.alert(errorMessage)

      if (throwError) throw e

      if (e.response && e.response.status === 401) {
        const currentLocation = window.location.href
        router.back()
        if (window.location.href == currentLocation) router.replace("/")
      }

      // we should never get here, but we need to return something for type safety
      return null as T
    }
  }
}

// this needs to be installed AFTER router plugin
export default {
  install(app, { router }: { router: Router }) {
    app.config.globalProperties.$graphql = createGraphQLClient(router)
    app.config.globalProperties.$axios = axios
    app.config.globalProperties.$db = reactive(db)
    app.config.globalProperties.$api = apiService
  },
}

declare module "vue" {
  interface ComponentCustomProperties {
    $graphql: GraphQLClient
    $axios: typeof axios
    $db: ActiveRecord
    $api: typeof apiService
  }
}
